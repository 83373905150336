import React, { useState, useEffect }  from 'react';
import classes from '../../css/prueba.module.css'; // Asegúrate de importar tus estilos CSS modules correctamente
import i18next from 'i18next';
import horseback from '../../img/ruta-a-riendas-banner-D-1.png';
import dividerh from '../../img/divider-h.svg';
import li from '../../img/li-icon.svg';
import dividerv from '../../img/vertical-divider.svg';
import whatsaap from '../../img/whatsapp-logo.svg';
import SEO from '../functions/MetaTags';

const Horseback = () => {
    
    const [shortTourOpen, setShortTourOpen] = useState(false);
    const [longTourOpen, setLongTourOpen] = useState(false);
     const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const isMobileScreen = window.innerWidth <= 768;
            setIsMobile(isMobileScreen);
            setShortTourOpen(!isMobileScreen); 
            setLongTourOpen(!isMobileScreen);
        };

        handleResize(); 
        window.addEventListener('resize', handleResize); 

        return () => {
            window.removeEventListener('resize', handleResize); 
        };
    }, []);

    const toggleShortTour = () => {
        if (isMobile) setShortTourOpen(!shortTourOpen); 
    };

    const toggleLongTour = () => {
        if (isMobile) setLongTourOpen(!longTourOpen); 
    };

    const lang = i18next.language.split('-')[0] || 'en'; 

    const content = {
        es: {
            name: "EcoRolling",
            seoTitle: "Ecorolling | Experiencia de Rutas a Caballo",
            image: "https://eco-rolling.com/static/media/Avistamiento-banner-D-1.e842420bc24c71ac97c6.png",
            seodescription:".",
            title: (
                <>
                    Eje Cafetero a Caballo <br />
                </>
            ),
            "subTitle": "Una Travesía de Tradición y Naturaleza",
            "description": "Embárcate en una experiencia única de conexión con la tierra y la cultura del Eje Cafetero en Colombia a través de nuestro apasionante paseo a caballo. Sumérgete en la belleza de los paisajes ondulantes, los campos de café y los secretos que solo se revelan cuando exploras la región a lomo de un caballo.",
            "shortTour": {
                "title": "Paseo Corto Guiado",
                "details": [
                    "<strong>Una Hora para Recordar</strong> Experimenta la emoción en cada minuto de 1 hora de recorrido lleno de adrenalina.",
                    "<strong>Grupo Exclusivo</strong> Con un máximo de 4 personas por recorrido, disfrutarás de una experiencia personalizada y exclusiva.",
                    "<strong>Explora con un Experto</strong> Nuestros guías especializados te llevarán a través de los tesoros ocultos del Eje Cafetero.",
                    "<strong>Sigue el Camino Perfecto</strong> Disfruta de un recorrido emocionante por rutas prediseñadas que te llevarán a lugares increíbles.",
                    "<strong>Detente y Captura la Belleza</strong> Haz paradas en miradores estratégicos para capturar las vistas más impresionantes y crear recuerdos inolvidables.",
                    "<strong>Tu Seguridad es Nuestra Prioridad</strong> Relájate y disfruta de la aventura con la tranquilidad de estar cubierto por nuestro seguro.",
                    "Energia en Movimiento disfruta de un delicioso refrigerio que te mantendra lleno de vitalidad durante todo el recorrido",
                    "Viaja con Comodidad Relajate mientras te llevamos de ida y regreso a tu destino, asegurando un viaje comodo y sin preocupaciones"
                ]
            },
            "longTour": {
                "title": "Paseo Largo Guiado",
                "details": [
                    "<strong>Una Hora para Recordar</strong> Experimenta la emoción en cada minuto de 1 hora de recorrido lleno de adrenalina.",
                    "<strong>Grupo Exclusivo</strong> Con un máximo de 4 personas por recorrido, disfrutarás de una experiencia personalizada y exclusiva.",
                    "<strong>Explora con un Experto</strong> Nuestros guías especializados te llevarán a través de los tesoros ocultos del Eje Cafetero.",
                    "<strong>Sigue el Camino Perfecto</strong> Disfruta de un recorrido emocionante por rutas prediseñadas que te llevarán a lugares increíbles.",
                    "<strong>Detente y Captura la Belleza</strong> Haz paradas en miradores estratégicos para capturar las vistas más impresionantes y crear recuerdos inolvidables.",
                    "<strong>Tu Seguridad es Nuestra Prioridad</strong> Relájate y disfruta de la aventura con la tranquilidad de estar cubierto por nuestro seguro.",
                    "<strong>Energia en Movimiento</strong> Disfruta de un delicioso refrigerio que te mantendra lleno de vitalidad durante todo el recorrido",
                    "<strong>Viaja con Comodidad</strong> Relajate mientras te llevamos de ida y regreso a tu destino, asegurando un viaje comodo y sin preocupaciones"
                ]
            },
            "recommendationsTitle": "Recomendaciones",
            "recommendationsText": "Se recomienda vestir botas de invierno, chaqueta cálida, impermeable, protector solar, sombrero, guantes y bufanda para mayor comodidad durante el paseo.",
            "embarkText": "Embárcate en esta emocionante experiencia a caballo mientras te sumerges en la tranquilidad y la serenidad del campo colombiano.",
            bannerSubtitle:(
            <>
            Tradición <br />Cafetera
            </> 
            ),
            bannerText: (
                <>
                Monta a <strong>caballo</strong> y siente la conexión <strong>única</strong> con la rica <strong>tradición cafetera</strong>
                </>
            ),
        },
        en: {
            name: "EcoRolling",
            seoTitle: "Ecorolling | Experience of Horseback Trails",
            image: "https://eco-rolling.com/static/media/Avistamiento-banner-D-1.e842420bc24c71ac97c6.png",
            seodescription:".",
            title: (
                <>
                    Coffee Axis on Horseback <br />
                </>
            ),
            "subTitle": "A Journey of Tradition and Nature",
            "description": "Embark on a unique experience of connecting with the land and culture of Colombia's Coffee Axis through our exciting horseback ride. Immerse yourself in the beauty of rolling landscapes, coffee fields, and secrets that are only revealed when you explore the region on horseback.",
            "shortTour": {
                "title": "Guided Short Ride",
                "details": [
                    "<strong>An Hour to Remember</strong> Feel the thrill in every minute of a 1-hour ride filled with adrenaline.",
                    "<strong>Exclusive Group</strong> With a maximum of 4 people per tour, you'll enjoy a personalized and exclusive experience.",
                    "<strong>Explore with an Expert</strong> Our specialized guides will take you through the hidden treasures of the Coffee Axis.",
                    "<strong>Follow the Perfect Path</strong> Enjoy an exciting ride through pre-designed routes that will take you to incredible places.",
                    "<strong>Stop and Capture the Beauty</strong> Make stops at strategic viewpoints to capture the most stunning views and create unforgettable memories.",
                    "<strong>Your Safety is Our Priority</strong> Relax and enjoy the adventure with the peace of mind that you are covered by our insurance.",
                    "Energy in Motion Enjoy a delicious snack that will keep you full of vitality throughout the ride.",
                    "Travel in Comfort Relax as we take you to and from your destination, ensuring a comfortable and worry-free trip."
                ]
            },
            "longTour": {
                "title": "Guided Long Ride",
                "details": [
                    "<strong>A Complete Experience</strong> Enjoy 4 hours of exciting exploration and discover all the destination's secrets.",
                    "<strong>Exclusive Group</strong> With a maximum of 4 people per tour, you'll enjoy a personalized and exclusive experience.",
                    "<strong>Explore with an Expert</strong> Our specialized guides will take you through the hidden treasures of the Coffee Axis.",
                    "<strong>Follow the Perfect Path</strong> Enjoy an exciting ride through pre-designed routes that will take you to incredible places.",
                    "<strong>Stop and Capture the Beauty</strong> Make stops at strategic viewpoints to capture the most stunning views and create unforgettable memories.",
                    "<strong>Your Safety is Our Priority</strong> Relax and enjoy the adventure with the peace of mind that you are covered by our insurance.",
                    "<strong>Energy in Motion</strong> Enjoy a delicious snack that will keep you full of vitality throughout the ride.",
                    "<strong>Travel in Comfort</strong> Relax as we take you to and from your destination, ensuring a comfortable and worry-free trip."
                ]
            },
            "recommendationsTitle": "Recommendations",
            "recommendationsText": "We recommend wearing winter boots, a warm jacket, a raincoat, sunscreen, a hat, gloves, and a scarf for maximum comfort during the ride.",
            "embarkText": "Embark on this exciting horseback experience while immersing yourself in the tranquility and serenity of the Colombian countryside.",
            bannerSubtitle: "Coffee Tradition",
            bannerText: <>
            Ride a <strong>horse</strong> and feel the <strong>unique</strong> connection with the rich <strong>coffee tradition</strong>
        </>
        }
    };

    const currentContent = content[lang] || content.en;
    
    return (
        <div className={classes['plan-detail-container']}>
            <SEO
                title={currentContent.seoTitle}
                description={currentContent.seodescription}
                image={currentContent.image}
                name={currentContent.name}
                
            />
            <section className={`flex-row ${classes['section-banner']} ${classes['ruta-a-riendas']}`}>
                <div className={classes['img-left']}>
                    <img src={horseback} alt="img of plan" className={classes['img']} />
                </div>
                <div className={`${classes['desc-right']} ${classes['flex-col']}`}>
                    <h2>
                        <span>{content[lang].title}</span>
                        {content[lang].subTitle}
                    </h2>
                    <img src={dividerh} alt="divider" className={`${classes['img']} ${classes['divider']}`} />
                    <p className={classes['section-content']}>
                        {content[lang].description}
                    </p>
                    <img src={dividerh} alt="divider" className={`${classes['img']} ${classes['div-inverse']}`} />
                </div>
            </section>

            {/* Detalles del Plan */}
            <section className={`${classes['flex-col']} ${classes['plan-details']}`}>
                <div className={`${classes['collapsible-wrapper']} ${classes.flex}`}>
                    <div className={`${classes['flex-col']} ${classes['collapsible-entry']}`}>
                        <button type="button" className={`${classes.collapsible} ${classes['ruta-corta']}`} onClick={toggleShortTour}>
                            {content[lang].shortTour.title}
                            <span className={classes['chevron-icon']}></span>
                        </button>
                        {shortTourOpen && (
                            <ul className={`${classes.content} ${classes['content-v1']} ${classes['content-v2']} ${shortTourOpen ? classes.show : classes['content-hidden']}`}>
                                {content[lang].shortTour.details.map((detail, index) => (

                                    <li key={index} className={`${classes.flex} ${classes['li-v1']} ${classes['li-v2']}`}>
                                        {index !== 6 && index !== 7 && (
                                            <img src={li} alt="icon" className={classes['img-icon']} />
                                        )}
                                        <span dangerouslySetInnerHTML={{ __html: detail }} className={index === 6 || index === 7 ? classes.empty : ''} ></span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <img src={dividerv} alt="divider" className={`${classes.img} ${classes['div-vertical']}`} />

                    <div className={`${classes['flex-col']} ${classes['collapsible-entry']}`}>
                        <button type="button" className={`${classes.collapsible} ${classes['ruta-a-riendas']} ${classes.longcollap}`} onClick={toggleLongTour}>
                            {content[lang].longTour.title}
                            <span className={classes['chevron-icon']}></span>
                        </button>
                        {longTourOpen && (
                            <ul className={`${classes.content} ${classes['content-v1']} ${classes['content-v2']} ${longTourOpen ? classes.show : classes['content-hidden']}`}>
                                {content[lang].longTour.details.map((detail, index) => (
                                    <li key={index} className={`${classes.flex} ${classes['li-v1']}  ${classes['li-v2']}`}>
                                        <img src={li} alt="icon" className={classes['img-icon']} />
                                        <span dangerouslySetInnerHTML={{ __html: detail }}/>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
                <a href="./plan-detail.html">
                    <button className={`${classes['main-btn']} ${classes['btn-centre']} ${classes["whatsap-desktop"]}`}>
                        <img src={whatsaap} alt="whatsapp-logo" className={classes['img-whatssap']} />
                        <h2 className={classes.contactitle}>{i18next.t('Ponte en Contacto')} </h2>
                    </button>
                </a>
                <div className={`${classes.flex} ${classes["mt-1"]} ${classes["mb-2"]} ${classes["plan-subtitle"]}`}>
                    <h3 className={classes["end-message"]}>
                        {content[lang].embarkText}
                    </h3>
                </div>
                <img src={dividerh} alt="divider" className={classes['div-inverse']} />
            </section>
            <section className={`${classes['flex-col']} ${classes['plan-detail-banner']} ${classes['plan-detail-banner-ruta-a-riendas']}`}>
                <div className={`${classes['hero-area-desc']} ${classes.flex} ${classes.deschorse}`}>
                    <h2> {content[lang].bannerSubtitle}  </h2>
                    <p>
                        {content[lang].bannerText}
                    </p>
                </div>
            </section>
        </div>
    );
};

export default Horseback;
