import React, { useState, useEffect } from 'react';
import 'swiper/swiper-bundle.css';
import classes from '../../css/prueba.module.css';
import dividerh from '../../img/divider-h.svg';
import li from '../../img/li-icon.svg';
import whatsaap from '../../img/whatsapp-logo.svg';
import cordillera from '../../img/plan-cordillera-principal.jpg';
import i18next from 'i18next';
import SEO from '../functions/MetaTags';

const PlanDetail = () => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    const toggleCollapse = () => {
        if (isMobile) {
            setIsCollapsed(!isCollapsed);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const isMobileScreen = window.innerWidth <= 768;
            setIsMobile(isMobileScreen);
            if (!isMobileScreen) {
                setIsCollapsed(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const lang = i18next.language.split('-')[0] || 'en';

    const content = {
        en: {
            name: "EcoRolling",
            seoTitle: "Ecorolling | Tour Mountain Range",
            image: "https://eco-rolling.com/static/media/Avistamiento-banner-D-1.e842420bc24c71ac97c6.png",
            seodescription:".",
            title: (
                <>
                    Mountain range tour
                    <span className={classes["mobile-break"]}><br /></span>
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>{" "}

                </>
            ),
            description: (
                <>
                    Embark on an exciting journey through the{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    Quindian Mountains - Córdoba, Pijao, and Buenavista -
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    aboard a classic Jeep Willys, a symbol of coffee culture.{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    This tour will take you along rural roads through some{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    of the most charming and picturesque towns in the region,{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    surrounded by mountains, coffee plantations, and spectacular{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    natural landscapes.
                </>
            ),
            includes: [
                "<strong>Airport or hotel pickup:</strong> Start your adventure with comfort and convenience. Don't worry about a thing; we take care of everything.",
                "<strong>Transportation in a Jeep Willys:</strong> Travel in one of the icons of the coffee region, the traditional Jeep Willys, a perfect vehicle for exploring mountainous and rural roads.",
                "<strong>Specialized tour guide:</strong> Accompanied by a local guide, an expert in the culture, history, and traditions of the region, you'll discover the best-kept secrets of each town.",
                "<strong>Lunch in Buenavista:</strong> Enjoy a delicious lunch at Río Azul, a typical regional restaurant where you can savor traditional dishes made with fresh, local ingredients.",
                "<strong>High-altitude coffee tasting:</strong> In Buenavista, along with breathtaking views, you'll enjoy a high-altitude coffee tasting, a delight for coffee lovers.",
                "<strong>Medical insurance:</strong> Travel with the peace of mind of having medical coverage for any unforeseen events that may arise during the tour.",
            ],
            collapsible: (
                <>
                    This Experience <span className={classes["mobile-break"]}><br /></span> Includes <span className={classes['chevron-icon']}></span>
                </>
            ),
            desc_end: (
                <>
                    Join us and let the magic of this extraordinary destination <span className={classes["desktop-break"]}><br /></span> captivate you. Don't miss this <span className={classes["desktop-break"]}><br /></span> unforgettable adventure!
                </>
            ),
            emblematicSpeciesTitle: (<> Mountain Range Adventure <br />An Authentic Journey</>),
            emblematicSpeciesDesc:
                <>
                    We guarantee you an experience that you'll remember
                    <span className={classes["desktop-break"]}><br /></span>forever!
                </>,
            stops: {
                title: 'Stops',
                details: [
                    {
                        title: 'First Stop: Córdoba',
                        description: 'The Art of Guadua Your adventure begins in the tranquil town of Córdoba, nationally recognized for its deep connection with guadua, a plant from the bamboo family that is essential in the region’s construction and culture.'
                    },
                    {
                        title: 'Second Stop: Pijao',
                        description: 'The Town of Tranquil Life After Córdoba, our journey continues to Pijao, a picturesque and charming town that is part of the international Cittaslow movement, a concept that promotes a slower lifestyle focused on enjoying life’s small pleasures.'
                    },
                    {
                        title: 'Third Stop: Buenavista',
                        description: 'At Eco Rolling, we believe in tourism that leaves a positive impact. We work closely with local communities and adopt sustainable practices at every step of our journeys.'
                    },
                ],                
        },
        Recomend: {
            title: 'Stops',
            details: [
                {
                    title: 'Tour Duration:',
                    description: 'Approximately 8 hours filled with unforgettable landscapes, fascinating stories, and the tranquility of the Quindian towns.'
                },
                {
                    title: 'Not Included:',
                    description: 'Unspecified or personal expenses, such as purchases of crafts or souvenirs.'
                },
                {
                    title: 'Recommendations',
                    description: 'It is recommended to wear comfortable clothing and insect repellent to fully enjoy the experience. <br/> <br/> The weather in the region can be changeable, so it is advisable to bring a light or waterproof jacket.'
                },
            ],
        },
        
    },
        es: {
            name: "EcoRolling",
            seoTitle: "Ecorolling | Tour por la cordillera",
            image: "https://eco-rolling.com/static/media/Avistamiento-banner-D-1.e842420bc24c71ac97c6.png",
            seodescription:".",
            title: (
                <>
                    Tour por la cordillera
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>{" "}
                </>
            ),
            description: (
                <>
                    Embárcate en un emocionante viaje por la {" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    Sierra Quindiana - Córdoba, Pijao y Buenavista -
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    a bordo de un clásico Jeep Willys, símbolo de la cultura cafetera.{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    Este recorrido te llevará por caminos rurales a través de algunos {" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    de los municipios más encantadores y pintorescos de la región,{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    rodeados de montañas, plantaciones de café y espectaculares
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    paisajes naturales.
                </>
            ),
            includes: [
                "<strong>Recolección en el aeropuerto u hotel:</strong> Empieza tu aventura con comodidad y conveniencia. No te preocupes por nada, ya que nos encargamos de todo.",
                "<strong>Transporte en Jeep Willys:</strong> Viaja en uno de los íconos del eje cafetero, el tradicional Jeep Willys, un vehículo perfecto para adentrarse en los caminos montañosos y rurales.",
                "<strong>Guía turístico especializado: </strong> Acompañado por un guía local, experto en la cultura, historia y tradiciones de la región, descubrirás los secretos mejor guardados de cada pueblo.",
                "<strong>Almuerzo en Buenavista:</strong> Disfruta de un delicioso almuerzo en Río Azul, un restaurante típico de la región, donde podrás degustar platos tradicionales elaborados con ingredientes frescos y locales.",
                "<strong>Degustación de café de altura:</strong>  En Buenavista, además de las impresionantes vistas, podrás disfrutar de una cata de café de altura, una experiencia que deleitará a los amantes del café.",
                "<strong> Seguro médico:</strong>, Viaja con la tranquilidad de contar con cobertura médica para cualquier imprevisto que pueda surgir durante el recorrido. ",
            ],

            collapsible: (
                <>
                    Esta Experiencia <span className={classes["mobile-break"]}><br /></span> Incluye <span className={classes['chevron-icon']}></span>
                </>
            ),
            desc_end: (
                <>
                    ¡Únete a nosotros y deja que la magia de este destino  <span className={classes["desktop-break"]}><br /></span> extraordinario te envuelva. ¡No te pierdas esta aventura <span className={classes["desktop-break"]}><br /></span> inolvidable!
                </>
            ),
            emblematicSpeciesTitle: (<> Aventura por la cordillera <br />Un viaje autentico</>),
            emblematicSpeciesDesc:
                <>
                    ¡Te garantizamos una experiencia que recordarás por
                    <span className={classes["desktop-break"]}><br /></span> siempre!
                </>,

            stops: {
                title: 'Paradas',
                details: [
                    {
                        title: 'Primera Parada: Córdoba',
                        description: 'El Arte de la Guadua Tu aventura comienza en el tranquilo pueblo de Córdoba, reconocido a nivel nacional por su profunda conexión con la guadua, una planta de la familia del bambú que es esencial en la construcción y cultura de la región.'
                    },
                    {
                        title: 'Segunda Parada: Pijao',
                        description: 'El Pueblo de la Vida Tranquila Después de Córdoba, nuestro recorrido continúa hacia Pijao, un pintoresco y encantador pueblo que forma parte del movimiento internacional Cittaslow, un concepto que promueve un estilo de vida más pausado y enfocado en disfrutar los pequeños placeres de la vida.'
                    },
                    {
                        title: 'Tercera Parada: Buenavista',
                        description: 'En Eco Rolling, creemos en un turismo que deja una huella positiva. Trabajamos en estrecha colaboración con comunidades locales y adoptamos prácticas sostenibles en cada paso de nuestros viajes'
                    },
                ],
        },
        Recomend: {
            title: 'Paradas',
            details: [
                {
                    title: 'Duracion del Tour:',
                    description: 'Aproximadamente 8 horas, llenas de paisajes inolvidables, historias fascinantes y la tranquilidad de los pueblos quindianos.'
                },
                {
                    title: 'No incluye:',
                    description: 'Gastos no especificados o personales, como compras de artesanías o souvenirs.'
                },
                {
                    title: 'Recomendaciones',
                    description: 'Se recomienda el uso de ropa cómoda y repelente de insectos para disfrutar plenamente de la experiencia. <br/>  <br/> El clima en la región puede ser cambiante, así que es aconsejable llevar una chaqueta ligera o impermeable.'
                },
            ],
    },

    },
};

const currentContent = content[lang] || content.en;
    return (
        <div>
         <SEO
                title={currentContent.seoTitle}
                description={currentContent.seodescription}
                image={currentContent.image}
                name={currentContent.name}
                
            />
            <main className={classes['main-content']}>
                {/* Sección de Detalles del Plan */}
                <section className={`${classes['flex-row']} ${classes['section-banner']} ${classes.avistamiento}`}>
                    <div className={classes['img-left']}>
                        <img
                            src={cordillera}
                            alt="Imagen del plan"
                            className={classes.img}
                        />
                    </div>
                    <div className={`${classes['desc-right']} ${classes['flex-col']}`}>
                        <h2>
                            {content[lang].title}
                        </h2>
                        <img src={dividerh} alt="divider" className={`${classes.img} ${classes.divider}`} />
                        <p>
                            {content[lang].description}

                        </p>
                        <img src={dividerh} alt="divider" className={classes['div-inverse']} />
                    </div>
                </section>

                <section className={`${classes['flex-col']} ${classes['plan-details']}`}>
                    <img src={dividerh} alt="divider" className={`${classes.img} ${classes.divider}`} />

                    <div className={`${classes['column-container']}`}>
                        {content[lang].stops.details.map((stop, index) => (
                            <div key={index} className={classes['column']}>
                                <h3 className={classes['column-title']}>{stop.title}</h3>
                                <p className={classes['column-description']}>{stop.description}</p>
                            </div>
                        ))}
                    </div>

                    <div className={`${classes['collapsible-wrapper']} ${classes.flex}`}>
                        <div className={`${classes['flex-col']} ${classes['collapsible-entry']} ${classes['collapsible-entry-v1']}`}>
                            <button
                                type="button"
                                className={`${classes.collapsible} ${classes.avistamiento} `}
                                onClick={toggleCollapse}
                                disabled={!isMobile}
                            >

                                <h2 className={classes.colltitle}>{content[lang].collapsible}</h2>
                            </button>
                            <ul className={`${classes.content} ${classes['content-v1']} ${isCollapsed ? classes['content-hidden'] : classes.show}`}>
                                {content[lang].includes.map((item, index) => (
                                    <li key={index} className={`${classes.flex} ${classes['li-v1']}`}>
                                        <img src={li} alt="icono" className={classes['img-icon']} />
                                        <span dangerouslySetInnerHTML={{ __html: item }}></span>
                                    </li>
                                ))}
                                <a href="./plan-detail.html">
                                    <button className={`${classes['main-btn']} ${classes['btn-centre']} ${classes["whatsap-mobile"]}`}>
                                        <img src={whatsaap} alt="whatsapp-logo" className={classes['img-whatssap']} />
                                        <h2 className={classes.contactitle}>{i18next.t('Ponte en Contacto')}</h2>
                                    </button>
                                </a>
                            </ul>
                        </div>
                    </div>

                    <a href="./plan-detail.html">
                        <button className={`${classes['main-btn']} ${classes['btn-centre']} ${classes["whatsap-desktop"]}`}>
                            <img src={whatsaap} alt="whatsapp-logo" className={classes['img-whatssap']} />
                            <h2 className={classes.contactitle}>{i18next.t('Ponte en Contacto')} </h2>
                        </button>
                    </a>
                    <div className={`${classes['column-container']}`}>
                        {content[lang].Recomend.details.map((Recomend, index) => (
                            <div key={index} className={classes['column']}>
                                <h3 className={classes['column-title']}>{Recomend.title}</h3>
                                <p className={classes['column-description']} dangerouslySetInnerHTML={{ __html: Recomend.description }} />
                            </div>
                        ))}
                    </div>
                    <img src={dividerh} alt="divider" className={classes['div-inverse']} />
                </section>
                {/* Sección de Especies Emblemáticas */}
                <section className={`${classes['flex-col']} ${classes['plan-detail-banner']} ${classes['plan-detail-banner-cordillera']}`}>
                    <div className={`${classes['hero-area-desc']} ${classes.flex} ${classes['des-caffetos']}`}>
                        <h2 className={classes["air-title"]}>{content[lang].emblematicSpeciesTitle}</h2>
                        <p>
                            {content[lang].emblematicSpeciesDesc}
                        </p>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default PlanDetail;
