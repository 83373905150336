import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Home from './Pages/Home';
import Plans from './Pages/Plans';
import BirdWatching from './Pages/BirdWatching';
import HorsebackTrails from './Pages/HorsebackTrails';
import DayCasaDuvelo from './Pages/DayCasaDuvelo';
import CoffeeExperience from './Pages/CoffeeExperience';
import JungleLights from './Pages/JungleLights';
import i18next from 'i18next';
import TourMountainRange from './Pages/TourMountainRange';
import AboutUs from './Pages/AboutUs';

const NotFound = () => <Navigate to="/" />;

function Layout() {
 
  const lang = i18next.language.split('-')[0] || 'en';

  const routes = {
    en: {
      birdWatching: '/experiences/bird-watching',
      horsebackTrails: '/experiences/horseback-trails',
      jungleLights: '/experiences/jungle-lights',
      dayCasaDuvelo: '/experiences/day-casa-duvelo',
      coffeeExperience: '/experiences/coffee-experience',
      TourMountainRange: '/experiences/tour-mountain-range',
    },
    es: {
      birdWatching: '/experiences/bird-watching',
      horsebackTrails: '/experiences/horseback-trails',
      jungleLights: '/experiences/jungle-lights',
      dayCasaDuvelo: '/experiences/day-casa-duvelo',
      coffeeExperience: '/experiences/coffee-experience',
      TourMountainRange: '/experiences/tour-mountain-range',
    },
  };  

  const currentRoutes = routes[lang] || routes.en; 

  return (
    <div>
      <BrowserRouter>
        <Header homeUrl="/" />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path={`/:lang/experiences`} element={<Plans />} />
          <Route path={`/:lang${currentRoutes.birdWatching}`} element={<BirdWatching />} />
          <Route path={`/:lang${currentRoutes.horsebackTrails}`} element={<HorsebackTrails />} />
          <Route path={`/:lang${currentRoutes.jungleLights}`} element={<JungleLights />} />
          <Route path={`/:lang${currentRoutes.dayCasaDuvelo}`} element={<DayCasaDuvelo />} />
          <Route path={`/:lang${currentRoutes.coffeeExperience}`} element={<CoffeeExperience />} />
          <Route path={`/:lang${currentRoutes.TourMountainRange}`} element={<TourMountainRange />} />
          <Route path={`/:lang/about-us`} element={<AboutUs />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default Layout;
