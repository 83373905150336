import React, { useState, useEffect } from 'react';
import 'swiper/swiper-bundle.css';
import classes from '../../css/prueba.module.css';
import dividerh from '../../img/divider-h.svg';
import li from '../../img/li-icon.svg';
import whatsaap from '../../img/whatsapp-logo.svg';
import duvelo from '../../img/casa-du-velo-D-1.png';
import i18next from 'i18next';
import SEO from '../functions/MetaTags';

const PlanDetail = () => {
    
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isMobile, setIsMobile] = useState(false); 

    const toggleCollapse = () => {
        if (isMobile) {
            setIsCollapsed(!isCollapsed);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const isMobileScreen = window.innerWidth <= 768;
            setIsMobile(isMobileScreen);
            if (!isMobileScreen) {
                setIsCollapsed(false); 
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const lang = i18next.language.split('-')[0] || 'en';

    const content = {
        en: {
            name: "EcoRolling",
            seoTitle: "Ecorolling | Day at Casa DuVelo",
            image: "https://eco-rolling.com/static/media/Avistamiento-banner-D-1.e842420bc24c71ac97c6.png",
            seodescription:".",
            title: (
                <>
                    Day at Casa DuVelo{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    designed to give you a 
                    {" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    unique experience{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                </>
            ),
            description: (
                <>
                    Discover the secrets of the cyclists
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>{" "}
                    from Colombia who are dominating the{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    top spots in the UCI world rankings {" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    with Casa DuVelo, located in the majestic  {" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    mountains of the Coffee Region in Colombia!{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                </>
            ),
            includes: [
                "<strong>Airport or hotel pickup:</strong> To start your adventure without worries.",
                "Enjoy a <strong>delicious snack</strong> to recharge your energy before starting your cycling day.",
                "<strong>Bike rental:</strong> All day to explore the region at your own pace.",
                "A <strong>tasty lunch</strong> that will let you savor the best of local cuisine.",
                "<strong>Rest and recover</strong> in the comfort of Casa DuVelo with an overnight stay included.",
                "Peace of mind guaranteed with our <strong>medical insurance</strong> for any unforeseen events.",
                "Stay hydrated throughout your journey with our <strong>hydration service</strong>."
            ],
            collapsible: (
                <>
                    This Experience <span className={classes["mobile-break"]}><br /></span> Includes <span className={classes['chevron-icon']}></span>
                </>
            ),
            desc_end: (
                <>
                    Join us and let the magic of this extraordinary destination <span className={classes["desktop-break"]}><br /></span> captivate you. Don't miss out on this <span className={classes["desktop-break"]}><br /></span> unforgettable adventure!
                </>
            ),
            emblematicSpeciesTitle:[],
            emblematicSpeciesDesc:[]
              
        },
           
        es: {
            name: "EcoRolling",
            seoTitle: "Ecorolling | Dia en Casa DuVelo",
            image: "https://eco-rolling.com/static/media/Avistamiento-banner-D-1.e842420bc24c71ac97c6.png",
            seodescription:".",
            title: (
                <>
                    Día en Casa DuVelo{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    diseñado para brindarte{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    una experiencia única{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    
                </>
            ),
            description: (
                <>
                     Descubre los secretos de los ciclistas
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>{" "}
                    colombianos que están dominando los{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    primeros lugares del ranking mundial de la{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    UCI con Casa DuVelo, ubicado en las{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    majestuosas montañas del Eje Cafetero en{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    Colombia!{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                </>
            ),
            includes: [
                "<strong>Recolección en el aeropuerto u hotel:</strong> Para comenzar tu aventura sin preocupaciones.",
                "Disfrute de un <strong>delicioso refrigerio</strong> Recargar energías antes de emprender tu día en bicicleta.",
                "<strong>Alquiler de bicicleta:</strong> Durante todo el día para explorar la región a tu propio ritmo.",
                "Un <strong>sabroso almuerzo</strong>  que te permitira degustar lo mejor de la gastronomía local.",
                "<strong>Descansa y recuperate</strong> en la comodidad de Casa Duvelo con una noche de hospedaje incluida",
                "Tranquilidad garantizada con nuestro <strong>Seguro médico</strong> para cualquier imprevisto.",
                "Mantente hidratado durante toda tu jornada con nuestro <strong>Servicio de hidratación:</strong>"
            ],            
            
            collapsible: (
                <>
                Esta Experiencia <span className={classes["mobile-break"]}><br /></span> Incluye <span className={classes['chevron-icon']}></span>
                </>
            ),
            desc_end: (
                <>
                ¡Únete a nosotros y deja que la magia de este destino  <span className={classes["desktop-break"]}><br /></span> extraordinario te envuelva. ¡No te pierdas esta aventura <span className={classes["desktop-break"]}><br /></span> inolvidable!
                </>
            ),
            emblematicSpeciesTitle: (<> Bajo el Manto <br /> Estrellado</>),
            emblematicSpeciesDesc:
                <>
                Nuestro <strong>Safari Nocturno</strong> te llevará a <span className={classes["desktop-break"]}><br /></span>través de caminos iluminados <span className={classes["desktop-break"]}><br /></span>
                únicamente por las <strong>estrellas</strong>.
                </>,
        },
    };

    const currentContent = content[lang] || content.en;

    return (
        <div>
                <SEO
                title={currentContent.seoTitle}
                description={currentContent.seodescription}
                image={currentContent.image}
                name={currentContent.name}
                
            />
            <main className={classes['main-content']}>
                {/* Sección de Detalles del Plan */}
                <section className={`${classes['flex-row']} ${classes['section-banner']} ${classes.avistamiento}`}>
                    <div className={classes['img-left']}>
                        <img
                            src={duvelo}
                            alt="Imagen del plan"
                            className={classes.img}
                        />
                    </div>
                    <div className={`${classes['desc-right']} ${classes['flex-col']}`}>
                        <h2>
                           {content[lang].title}
                        </h2>
                        <img src={dividerh} alt="divider" className={`${classes.img} ${classes.divider}`} />
                        <p>
                           {content[lang].description}

                        </p>
                        <img src={dividerh} alt="divider" className={classes['div-inverse']} />
                    </div>
                </section>

                <section className={`${classes['flex-col']} ${classes['plan-details']}`}>
                    <img src={dividerh} alt="divider" className={`${classes.img} ${classes.divider}`} />

                    <div className={`${classes['collapsible-wrapper']} ${classes.flex}`}>
                        <div className={`${classes['flex-col']} ${classes['collapsible-entry']} ${classes['collapsible-entry-v1']}`}>
                            <button
                                type="button"
                                className={`${classes.collapsible} ${classes.avistamiento}`}
                                onClick={toggleCollapse}
                                disabled={!isMobile}
                            >

                                <h2 className={classes.colltitle}>{content[lang].collapsible}</h2>
                            </button>
                            <ul className={`${classes.content} ${classes['content-v1']} ${isCollapsed ? classes['content-hidden'] : classes.show}`}>
                                {content[lang].includes.map((item, index) => (
                                    <li key={index} className={`${classes.flex} ${classes['li-v1']}`}>
                                        <img src={li} alt="icono" className={classes['img-icon']} />
                                        <span dangerouslySetInnerHTML={{ __html: item }}></span>
                                    </li>
                                ))}
                                <a href="./plan-detail.html">
                                    <button className={`${classes['main-btn']} ${classes['btn-centre']} ${classes["whatsap-mobile"]}`}>
                                        <img src={whatsaap} alt="whatsapp-logo" className={classes['img-whatssap']} />
                                        <h2 className={classes.contactitle}>{i18next.t('Ponte en Contacto')}</h2>
                                    </button>
                                </a>
                            </ul>
                        </div>
                    </div>

                    <a href="./plan-detail.html">
                        <button className={`${classes['main-btn']} ${classes['btn-centre']} ${classes["whatsap-desktop"]}`}>
                            <img src={whatsaap} alt="whatsapp-logo" className={classes['img-whatssap']} />
                            <h2 className={classes.contactitle}>{i18next.t('Ponte en Contacto')} </h2>
                        </button>
                    </a>
                    <div className={`${classes.flex} ${classes["mt-1"]} ${classes["mb-2"]} ${classes["plan-subtitle"]}`}>
                        <h3 className={classes["end-message"]}>
                        {content[lang].desc_end}
                        </h3>
                    </div>
                    <img src={dividerh} alt="divider" className={classes['div-inverse']} />
                </section>
                {/* Sección de Especies Emblemáticas */}
                <section className={`${classes['flex-col']} ${classes['plan-detail-banner']} ${classes['plan-detail-banner-dia-duvelo']}`}>
                </section>
            </main>
        </div>
    );
};

export default PlanDetail;
