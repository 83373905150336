import React, { useState, useEffect } from 'react';
import 'swiper/swiper-bundle.css';
import classes from '../../css/prueba.module.css';
import dividerh from '../../img/divider-h.svg';
import li from '../../img/li-icon.svg';
import whatsaap from '../../img/whatsapp-logo.svg';
import avistamiento from '../../img/Avistamiento-banner-D-1.png';
import i18next from 'i18next';
import SEO from '../functions/MetaTags';

const PlanDetail = () => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    const toggleCollapse = () => {
        if (isMobile) {
            setIsCollapsed(!isCollapsed);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const isMobileScreen = window.innerWidth <= 768;
            setIsMobile(isMobileScreen);
            if (!isMobileScreen) {
                setIsCollapsed(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const lang = i18next.language.split('-')[0] || 'en';

    const content = {
        en: {
            name: "EcoRolling",
            seoTitle: "Ecorolling | Experience the Magic of Birdwatching",
            image: "https://eco-rolling.com/static/media/Avistamiento-banner-D-1.e842420bc24c71ac97c6.png",
            title: (
                <>
                    Experience the Magic of
                    <span className={`${classes["desktop-break"]}${classes["mobile-break"]}`}>
                        <br />
                    </span>
                    Birdwatching
                </>
            ),
            seodescription:"Discover vibrant bird species and unmatched biodiversity in Colombia’s Coffee Region, home to over 245 species—a birdwatcher's paradise.",
            description: (
                <>
                    In the heart of Colombia's Coffee Region,
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>{" "}
                    we invite you to discover a world{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    of fascinating colorful plumage, melodic{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    songs, and unmatched avian biodiversity.{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    With over 245 recorded bird species,{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    birdwatching in this region becomes{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>{" "}
                    a unique and captivating experience.
                </>

            ),
            collapsible: (
                <>
                    This Experience <span className={classes["mobile-break"]}><br /></span> Includes <span className={classes['chevron-icon']}></span>
                </>
            ),
            includes: [
                "<strong>Bilingual specialized guide</strong> that will take you to the best birdwatching spots and provide you with detailed information about each species you encounter.",
                "Enjoy a <strong>luxury breakfast</strong> to start your day full of energy.",
                "<strong>High-quality binoculars</strong> so you don’t miss a single detail of the birds you observe.",
                "<strong>Waterproof boots</strong> to ensure your comfort and safety while exploring the various habitats where the birds reside.",
                "A <strong>checklist of observed birds</strong> so you can record and remember all the species you saw during your adventure.",
                "<strong>Insurance</strong> that gives you peace of mind and protection throughout the activity.",
                "<strong>Pick-up from the airport or hotel</strong> to start your adventure with ease and without worries.",
                "Complete peace of mind with our <strong>medical insurance</strong>, so you can enjoy the day without any mishaps.",
                "Indulge in a <strong>delicious snack</strong> to keep you energized as you explore nature.",
                "We will dedicate <strong>an entire day</strong> to the fascinating world of <strong>birdwatching</strong>, exploring the best spots to observe a wide variety of species.",
                "<strong>Round-trip transportation</strong> from your location to the birdwatching sites, ensuring your comfort and safety throughout the journey.",
            ],
            desc_end: (
                <>
                    Dive into the magic of birdwatching in the <span className={classes["desktop-break"]}><br /></span> beautiful Coffee Cultural Landscape of Colombia with our <span className={classes["desktop-break"]}><br /></span> exciting tour plan!
                </>
            ),
            emblematicSpeciesTitle: <>Emblematic <br /> Species</>,
            emblematicSpeciesDesc:
                <>
                    You will be amazed by the presence of iconic birds such as the <strong>hummingbird</strong>, the <strong>toucan</strong>, and the <strong>woodpecker</strong>, among many other colorful and unique species.
                </>,
        },
        es: {
            seoTitle: "Ecorolling | Experimenta la magia de la observación de aves",
            name: "EcoRolling",
            title: (
                <>
                    Vive la Magia del{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    Avistamiento de Aves{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    en el Eje Cafetero{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    de Colombia
                </>
            ),
            image: "https://eco-rolling.com/static/media/Avistamiento-banner-D-1.e842420bc24c71ac97c6.png",
            seodescription:"Descubre coloridas aves y una biodiversidad inigualable en la Región Cafetera de Colombia, hogar de más de 245 especies—un paraíso para observadores.",
            description: (
                <>
                    En el corazón del Eje Cafetero colombiano,
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>{" "}
                    te invitamos a descubrir un mundo{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    fascinante de plumajes coloridos, cantos{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    melodiosos y una biodiversidad aviar{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    incomparable. Con más de 245 especies de{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    aves registradas, nuestro avistamiento de{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>{" "}
                    aves en esta región se convierte en una{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>{" "}
                    experiencia única y cautivadora.
                </>
            ),
            includes: [
                "<strong>Guianza bilingüe especializada</strong> que te llevará a los mejores lugares para avistar aves y te brindará información detallada sobre cada especie que encuentres.",
                "Disfruta de un <strong>desayuno de lujo</strong>, para empezar el día con energía.",
                "<strong>Binoculares de alta calidad</strong> para que no te pierdas ni un solo detalle de las aves que observes.",
                "<strong>Botas Pantaneras</strong> para garantizar tu comodidad y seguridad mientras exploras los diversos hábitats donde habitan las aves.",
                "Lista de <strong>chequeo de aves observadas</strong> para que puedas registrar y recordar todas las especies que hayas visto durante tu aventura.",
                "<strong>Seguro</strong> que te brinda tranquilidad y protección durante toda la actividad.",
                "<strong>Recolección en el aeropuerto u hotel</strong> para comenzar tu aventura con comodidad y sin preocupaciones.",
                "Tranquilidad absoluta con nuestro <strong>seguro médico</strong>, para que puedas disfrutar del día sin contratiempos.",
                "Deléitate con un <strong>delicioso refrigerio</strong> que te brindará energía para explorar la naturaleza.",
                "Dedicaremos <strong>un día completo</strong> al apasionante mundo del <strong>avistamiento de aves</strong>, explorando los mejores lugares para observar una amplia variedad de especies.",
                "<strong>Transporte ida y regreso</strong> desde tu ubicación hasta los sitios de avistamiento, garantizando tu comodidad y seguridad durante todo el recorrido.",
            ],
            collapsible: (
                <>
                    Esta Experiencia <span className={classes["mobile-break"]}><br /></span> Incluye <span className={classes['chevron-icon']}></span>
                </>
            ),
            desc_end: (
                <>
                    ¡Sumérgete en la magia del avistamiento de aves en el <span className={classes["desktop-break"]}><br /></span> hermoso Eje Cafetero de Colombia con  nuestro <span className={classes["desktop-break"]}><br /></span> emocionante plan turístico!
                </>
            ),
            emblematicSpeciesTitle: (<>Especies <br /> Emblemáticas</>),
            emblematicSpeciesDesc:
                <>
                    Podrás maravillarte con la presencia de aves emblemáticas, como el <strong>colibrí</strong>,
                    el <strong>tucán</strong> y el <strong>pájaro carpintero</strong>, entre muchas otras
                    especies coloridas y singulares.
                </>,
        },
    };

    const currentContent = content[lang] || content.en;

    return (
        <div>
            <SEO
                title={currentContent.seoTitle}
                description={currentContent.seodescription}
                image={currentContent.image}
                name={currentContent.name}
                
            />
            <main className={classes['main-content']}>
                {/* Sección de Detalles del Plan */}
                <section className={`${classes['flex-row']} ${classes['section-banner']} ${classes.avistamiento}`}>
                    <div className={classes['img-left']}>
                        <img
                            src={avistamiento}
                            alt="Imagen del plan"
                            className={classes.img}
                        />
                    </div>
                    <div className={`${classes['desc-right']} ${classes['flex-col']}`}>
                        <h2>
                            {content[lang].title}
                        </h2>
                        <img src={dividerh} alt="divider" className={`${classes.img} ${classes.divider}`} />
                        <p>
                            {content[lang].description}

                        </p>
                        <img src={dividerh} alt="divider" className={classes['div-inverse']} />
                    </div>
                </section>

                <section className={`${classes['flex-col']} ${classes['plan-details']}`}>
                    <img src={dividerh} alt="divider" className={`${classes.img} ${classes.divider}`} />

                    <div className={`${classes['collapsible-wrapper']} ${classes.flex}`}>
                        <div className={`${classes['flex-col']} ${classes['collapsible-entry']} ${classes['collapsible-entry-v1']}`}>
                            <button
                                type="button"
                                className={`${classes.collapsible} ${classes.avistamiento}`}
                                onClick={toggleCollapse}
                                disabled={!isMobile}
                            >

                                <h2 className={classes.colltitle}>{content[lang].collapsible}</h2>
                            </button>
                            <ul className={`${classes.content} ${classes['content-v1']} ${isCollapsed ? classes['content-hidden'] : classes.show}`}>
                                {content[lang].includes.map((item, index) => (
                                    <li key={index} className={`${classes.flex} ${classes['li-v1']}`}>
                                        <img src={li} alt="icono" className={classes['img-icon']} />
                                        <span dangerouslySetInnerHTML={{ __html: item }}></span>
                                    </li>
                                ))}
                                <a href="./plan-detail.html">
                                    <button className={`${classes['main-btn']} ${classes['btn-centre']} ${classes["whatsap-mobile"]}`}>
                                        <img src={whatsaap} alt="whatsapp-logo" className={classes['img-whatssap']} />
                                        <h2 className={classes.contactitle}>{i18next.t('Ponte en Contacto')}</h2>
                                    </button>
                                </a>
                            </ul>
                        </div>
                    </div>

                    <a href="./plan-detail.html">
                        <button className={`${classes['main-btn']} ${classes['btn-centre']} ${classes["whatsap-desktop"]}`}>
                            <img src={whatsaap} alt="whatsapp-logo" className={classes['img-whatssap']} />
                            <h2 className={classes.contactitle}>{i18next.t('Ponte en Contacto')} </h2>
                        </button>
                    </a>
                    <div className={`${classes.flex} ${classes["mt-1"]} ${classes["mb-2"]} ${classes["plan-subtitle"]}`}>
                        <h3 className={classes["end-message"]}>
                            {content[lang].desc_end}
                        </h3>
                    </div>
                    <img src={dividerh} alt="divider" className={classes['div-inverse']} />
                </section>
                <section className={`${classes['flex-col']} ${classes['plan-detail-banner']} ${classes['plan-detail-banner-avistamiento']}`}>
                    <div className={`${classes['hero-area-desc']} ${classes.flex}`}>
                        <h2 className={classes["air-title"]}>{content[lang].emblematicSpeciesTitle}</h2>
                        <p>
                            {content[lang].emblematicSpeciesDesc}
                        </p>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default PlanDetail;
