import React from "react";
import "swiper/swiper-bundle.css";
import classes from "../../css/prueba.module.css";
import dividerh from "../../img/divider-h.svg";
import nosotros from "../../img/Nosotros-banneer-D.png";
import i18next from "i18next";
import SEO from "../functions/MetaTags";
import Nosotros_seguridad from "../../img/Nosotros-seguridad.png";
import Nosotros_insp from "../../img/Nosotros-nuestra-insp.png";
import Nosotros_nature from "../../img/Nosotros-naturaleza.png";
import Nosotros_friendly from "../../img/Nosotros-eco-friendly.png";
import Nosotros_compromiso from "../../img/Nosotros-compromiso.png";

const PlanDetail = () => {

    const lang = i18next.language.split("-")[0] || "en";

    const content = {
        en: {
            desc_image: "¡Discover Colombia <br/> with Eco Rolling!",
            name: "EcoRolling",
            seoTitle: "Ecorolling | About us",
            image:
                "https://eco-rolling.com/static/media/Avistamiento-banner-D-1.e842420bc24c71ac97c6.png",
            seodescription: ".",
            title: (
                <>
                    About us
                    <span className={classes["mobile-break"]}>
                        <br />
                    </span>
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>{" "}
                </>
            ),
            description: (
                <>
                    At Eco Rolling, we were born with the mission of changing the{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    perspective of tourism in Colombia. We are a new and exciting{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    proposal in the market, and our main purpose is to show the wonders{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    of this country as a sustainable, eco-friendly and safe tourist{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    destination.{" "}
                </>
            ),
            items: {
                title: "items",
                details: [
                    {
                        title: "Our Inspiration",
                        description:
                            "Colombia is a treasure trove of diversity, from its stunning landscapes to its vibrant local cultures. We draw inspiration from the country`s natural and cultural wealth to offer unique and environmentally friendly travel experiences.",
                        image: Nosotros_insp,
                    },
                    {
                        title: "Safety First",
                        description:
                            "The safety of our travelers is our number one priority. We work diligently to ensure that every destination and activity we offer meets the highest safety standards.",
                        image: Nosotros_seguridad,
                    },
                    {
                        title: "Commitment to Sustainability",
                        description:
                            "At Eco Rolling, we believe in tourism that leaves a positive footprint. We work closely with local communities and adopt sustainable practices at every step of our journeys.",
                        image: Nosotros_compromiso,
                    },
                    {
                        title: "Eco-friendly experiences",
                        description:
                            "From sustainable accommodations to responsible travel practices, we strive to ensure that your eco-friendly experience is a living testament to our commitment to environmental conservation.",
                        image: Nosotros_friendly,
                    },
                    {
                        title: "Immerse yourself in nature",
                        description:
                            "Our eco-friendly experiences will allow you to immerse yourself in the majesty of nature without leaving a negative footprint. Discover the richness of Colombian ecosystems, from lush forests to pristine coasts, on a trip that will connect you with nature in a unique and respectful way.",
                        image: Nosotros_nature,
                    },
                ],
            },
        },
        es: {
            desc_image: "¡Descubre Colombia <br/> con Eco Rolling!",
            name: "EcoRolling",
            seoTitle: "Ecorolling | Tour por la cordillera",
            image:
                "https://eco-rolling.com/static/media/Avistamiento-banner-D-1.e842420bc24c71ac97c6.png",
            seodescription: ".",
            title: (
                <>
                    Nosotros
                    <span className={classes["mobile-break"]}>
                        <br />
                    </span>
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>{" "}
                </>
            ),
            description: (
                <>
                    En Eco Rolling, nacimos con la misión de cambiar la{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    perspectiva del turismo en Colombia. Somos una propuesta nueva y emocionante{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    en el mercado, y nuestro principal propósito es mostrar las maravillas{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>
                    de este país como un destino turístico sostenible, ecológico y seguro.{" "}
                    <span className={classes["desktop-break"]}>
                        <br />
                    </span>{" "}
                </>
            ),            

            items: {
                title: "Items",
                details: [
                    {
                        title: "Nuestra Inspiración",
                        description:
                            "Colombia es un tesoro de diversidad, desde sus paisajes impresionantes hasta sus vibrantes culturas locales. Nos inspiramos en la riqueza natural y cultural del país para ofrecer experiencias de viaje únicas y amigables con el medio ambiente.",
                        image: Nosotros_insp,
                    },
                    {
                        title: "Seguridad Primero",
                        description:
                            "La seguridad de nuestros viajeros es nuestra prioridad número uno. Trabajamos diligentemente para garantizar que cada destino y actividad que ofrecemos cumpla con los más altos estándares de seguridad.",
                        image: Nosotros_seguridad,
                    },
                    {
                        title: "Compromiso con la Sostenibilidad",
                        description:
                            "En Eco Rolling, creemos en un turismo que deja una huella positiva. Trabajamos en estrecha colaboración con comunidades locales y adoptamos prácticas sostenibles en cada paso de nuestros viajes.",
                        image: Nosotros_compromiso,
                    },
                    {
                        title: "Experiencias ecológicas",
                        description:
                            "Desde alojamientos sostenibles hasta prácticas de viaje responsables, nos esforzamos por asegurarnos de que tu experiencia ecológica sea un testimonio vivo de nuestro compromiso con la conservación del medio ambiente.",
                        image: Nosotros_friendly,
                    },
                    {
                        title: "Sumérgete en la naturaleza",
                        description:
                            "Nuestras experiencias ecológicas te permitirán sumergirte en la majestuosidad de la naturaleza sin dejar una huella negativa. Descubre la riqueza de los ecosistemas colombianos, desde frondosos bosques hasta costas prístinas, en un viaje que te conectará con la naturaleza de una manera única y respetuosa.",
                        image: Nosotros_nature,
                    },
                ],
            },
            
        },
    };

    const currentContent = content[lang] || content.en;

    return (
        <div>
            <SEO
                title={currentContent.seoTitle}
                description={currentContent.seodescription}
                image={currentContent.image}
                name={currentContent.name}
            />
            <main className={classes["main-content"]}>
                <section
                    className={`${classes["flex-row"]} ${classes["section-banner"]} ${classes.avistamiento}`}
                >
                    <div className={`${classes["desc-right"]} ${classes["flex-col"]}`}>
                        <h2>{content[lang].title}</h2>
                        <img
                            src={dividerh}
                            alt="divider"
                            className={`${classes.img} ${classes.divider}`}
                        />
                        <p>{content[lang].description}</p>
                        <img
                            src={dividerh}
                            alt="divider"
                            className={classes["div-inverse"]}
                        />
                    </div>

                    <div className={classes["img-left"]}>
                        <img src={nosotros} alt="Imagen del plan" className={classes.img} />
                        <h2
                            className={classes["air-title"]}
                            dangerouslySetInnerHTML={{ __html: content[lang].desc_image }}
                        ></h2>
                    </div>
                </section>

                <section
                    className={`${classes["flex-col"]} ${classes["plan-details"]}`}
                >
                    <img
                        src={dividerh}
                        alt="divider"
                        className={`${classes.img} ${classes.divider}`}
                    />

                    <div
                        className={`${classes["plans-container"]} ${classes["grid-container"]} ${classes["mt-1"]} ${classes["mb-2"]}`}
                    >
                        {/* Fila superior: 3 elementos */}
                        <div className={`${classes["row"]}`}>
                            {content[lang].items.details.slice(0, 3).map((item, index) => (
                                <div key={index} className={classes["column"]}>
                                    <img
                                        src={item.image}
                                        alt={`${item.title}`}
                                        className={classes["column-image"]}
                                    />
                                    <h3 className={classes["column-title"]}>{item.title}</h3>
                                    <p className={classes["column-description"]}>
                                        {item.description}
                                    </p>
                                </div>
                            ))}
                        </div>

                        {/* Fila inferior: 2 elementos */}
                        <div className={`${classes["row"]}`}>
                            {content[lang].items.details.slice(3, 5).map((item, index) => (
                                <div key={index} className={classes["column"]}>
                                    <img
                                        src={item.image}
                                        alt={`${item.title}`}
                                        className={classes["column-image"]}
                                    />
                                    <h3 className={classes["column-title"]}>{item.title}</h3>
                                    <p className={classes["column-description"]}>
                                        {item.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <img src={dividerh} alt="divider" className={classes['div-inverse']} />
                </section>
            </main>
        </div>
    );
};

export default PlanDetail;
